import React, {useEffect, useState} from 'react'
import {Drawer, Grid, Paper} from "@mui/material";
import RoomsTable from "../components/RoomsTable";
import RoomForm from "../components/RoomForm";
import axios from "axios";
import userManager from "../UserManager";

export default function Rooms() {
    const [data, setData] = useState({data: {rooms: []}});
    const [drawerExpanded, setDrawerExpanded] = useState(false);
    const [roomFormValues, setRoomFormValues] = useState({});
    const [roomAction, setRoomAction] = React.useState('edit');

    useEffect(() => {
        userManager.getUser().then((user) => {
            axios.get(process.env.REACT_APP_API_BASE + '/room?per_page=100', {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }
            ).then((response) => {
                setData(response.data);
            });
        });
    }, []);

    if (!data) return null;

    const onDelete = (e, values) => {
        userManager.getUser().then((user) => {
            axios.delete(process.env.REACT_APP_API_BASE + '/room/' + values.room_id, {
                'headers': {
                    'Authorization': 'Bearer ' + user.access_token
                }
            }).then((response) => {
                axios.get(process.env.REACT_APP_API_BASE + '/room', {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                }).then((response) => {
                    setData(response.data);
                    setDrawerExpanded(false);
                })
            })
        });
    };

    const onSubmit = (values) => {
        console.log(roomAction);
        userManager.getUser().then((user) => {
            if (roomAction == 'edit') {
                axios.put(process.env.REACT_APP_API_BASE + '/room/' + values.room_id, {
                        name: values.room_name,
                        passcode: values.room_pin,
                        owner: values.owner,
                        hold_music: values.hold_music
                    },
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + user.access_token
                        }
                    }).then(( response ) => {
                    axios.get(process.env.REACT_APP_API_BASE + '/room', {
                        'headers': {
                            'Authorization': 'Bearer ' + user.access_token
                        }
                    }).then((response) => {
                        setData(response.data);
                        setDrawerExpanded(false);
                    })
                })
            } else {
                axios.post(process.env.REACT_APP_API_BASE + '/room', {
                        id: values.room_id,
                        name: values.room_name,
                        passcode: values.room_pin,
                        owner: values.owner,
                        hold_music: values.hold_music
                    },
                    {
                        'headers': {
                            'Authorization': 'Bearer ' + user.access_token
                        }
                    }).then(( response ) => {
                    axios.get(process.env.REACT_APP_API_BASE + '/room', {
                        'headers': {
                            'Authorization': 'Bearer ' + user.access_token
                        }
                    }).then((response) => {
                        setData(response.data);
                        setDrawerExpanded(false);
                    })
                })
            }

        })
    }

    const handleRowClick = (e, row) => {
        setRoomFormValues({
            room_id: row.id,
            room_name: row.name,
            room_pin: row.passcode,
            owner: row.owner,
            hold_music: row.hold_music
        })
        setDrawerExpanded(true);
    }

    const handleDrawerClose = (e) => {
        setDrawerExpanded(false);
    }

    return (
        <>
        <Drawer
            anchor="right"
            open={drawerExpanded}
            onClose={handleDrawerClose}
            >
            <Paper
                elevation={0}
                sx={{
                    m: 2
                }}>
                <RoomForm setRoomAction={setRoomAction} onCancel={handleDrawerClose} onSubmit={onSubmit} onDelete={onDelete} initialValues={roomFormValues}/>
            </Paper>
        </Drawer>
        <Grid container>
            <RoomsTable rows={data.rooms} handleRowClick={handleRowClick}/>
        </Grid>
        </>
    )
}