import {Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import { useParams } from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import Moment from 'react-moment';
import userManager from "../UserManager";

export default function RoomParticipants( {roomId} ) {
    const [data, setData] = useState({participants: null});
    const params = useParams();

    const GetParticipants = async () => {
        try {
            const user = await userManager.getUser();
            const Participants = await
                axios.get(process.env.REACT_APP_API_BASE + '/room/' + params.roomId + '/participants', {
                    'headers': {
                        'Authorization': 'Bearer ' + user.access_token
                    }
                });
            setData(Participants.data);
        } catch (err) {
            console.log("Error getting participant data")
            console.log(err)
        }
    }

    useEffect(() => {
        GetParticipants();
        const interval=setInterval(()=>{
            GetParticipants()
        },15000)


        return()=>clearInterval(interval)
    }, []);

    if (!data.participants) return (<Typography>Room is Empty.</Typography>);

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Number</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Joined</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {data.participants &&
                    data.participants.map((participant) => {
                    return (
                    <TableRow key={participant.call_sid}>
                        <TableCell>{participant.from_formatted}</TableCell>
                        <TableCell>{participant.caller_name}</TableCell>
                        <TableCell><Moment fromNow>{participant.start_time}</Moment></TableCell>
                    </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    )
}