import React, {useState} from 'react'
import Typography from "@mui/material/Typography";
import {AppBar, Toolbar, Box} from "@mui/material";
import { useAuth } from 'oidc-react';
import IconButton from '@mui/material/IconButton';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { NavLink } from "react-router-dom";


export default function Layout( { children }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const auth = useAuth();

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleSignout = () => {
        auth.signOutRedirect();
    };

    if (auth.isLoading) return null;

    return (
        <Box sx={{ display: 'flex'}}>
            <AppBar
                elevation={5}
                color="primary"
                >
                <Toolbar>
                    <Typography
                        variant="h6"
                        sx={{ color: "white", flexGrow: 1, textDecoration: "none", boxShadow: "none" }}
                        noWrap
                        component={NavLink}
                        to={"/conference-rooms/rooms"}
                    >
                        Rooms Admin Panel
                    </Typography>
                    <Typography>
                        {auth.userData.profile.given_name} {auth.userData.profile.family_name.charAt(0)}.
                    </Typography>
                    <div>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleSignout}>Sign out</MenuItem>
                        </Menu>
                    </div>
                </Toolbar>
            </AppBar>

            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
            >
                <Toolbar />
                {children}
            </Box>
        </Box>
    )
}