import { ThemeProvider, createTheme } from '@mui/material/styles';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import Rooms from "./pages/Rooms"
import Layout from "./components/Layout"
import userManager from "./UserManager"
import {AuthProvider} from "oidc-react";

import './App.css';
import RoomParticipants from "./pages/RoomParticipants";

const theme = createTheme();

userManager.events.addAccessTokenExpired(function(){
    userManager.signoutRedirect();
});

function App() {
    return (
        <AuthProvider
            onSignIn = {async () => {
                window.location.hash = '';
                window.location.search = '';
            }}

            userManager={userManager}>
            <ThemeProvider theme={theme}>
            <Router>
                <Layout>
                    <Routes>
                        <Route exact path="/conference-rooms/rooms" element={<Rooms />} />
                        <Route path="/conference-rooms/rooms/:roomId/participants" element={<RoomParticipants />} />
                    </Routes>
                </Layout>
            </Router>
            </ThemeProvider>
        </AuthProvider>
    );
}

export default App;
