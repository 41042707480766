import {UserManager} from "oidc-react";
import {WebStorageStateStore} from "oidc-client";

const userManager = new UserManager({
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: process.env.REACT_APP_OAUTH_URL,
    client_id: process.env.REACT_APP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    silent_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI,
    response_type: 'code',
    scope: 'openid profile',
    loadUserInfo: true,
    automaticSilentRenew: true,
});

export default userManager;