import React, {useEffect} from 'react'
import { Form } from 'react-final-form';
import {TextField, Select} from "mui-rff";
import {Button, MenuItem, FormControl, Stack} from "@mui/material";

const RoomForm = ({initialValues, onSubmit, onDelete, onCancel, setRoomAction}) => {

    useEffect(() => {
        if (initialValues.room_id == undefined) {
            setRoomAction("new");
        } else {
            setRoomAction("edit");
        }
    }, []);

    async function validate( values ) {
        if (!values.room_id) {
            return { room_id: "Room ID is required"}
        }

        if (!values.room_name) {
            return { room_name: "Room Name is required"}
        }
        return;
    }

    const render = ({handleSubmit}) => {
        return (
                <form onSubmit={handleSubmit} noValidate>
                    <Stack>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
                            <TextField
                                label="Room ID"
                                name="room_id"
                                required={true}
                                variant="standard"
                                type="number"
                                margin="normal"
                                autoFocus
                                inputProps={{
                                    action: "test-action"
                                }}
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
                            <TextField
                                label="Room Name"
                                name="room_name"
                                required={true}
                                variant="standard"
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
                            <TextField
                                label="PIN"
                                name="room_pin"
                                required={true}
                                variant="standard"
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
                            <TextField
                                label="Owner"
                                name="owner"
                                required={false}
                                variant="standard"
                                margin="normal"
                            />
                        </FormControl>
                        <FormControl variant="standard" sx={{ m: 1, minWidth: 320 }}>
                            <Select name="hold_music" label="On Hold Music">
                                <MenuItem value="twilio-default">Default</MenuItem>
                                <MenuItem value="only-person-in-room">No Music On Hold</MenuItem>
                            </Select>
                        </FormControl>
                    </Stack>
                    <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        sx={{
                            ml: 1,
                            mr: 1
                        }}
                    >Save</Button>
                    {initialValues.room_id &&
                        <Button
                            type="button"
                            color="error"
                            variant='outlined'
                            onClick={(event) => onDelete(event, initialValues)}
                            sx={{
                                ml: 1,
                                mr: 1
                            }}
                        >Delete Room</Button>}
                    <Button
                        type='button'
                        variant='outlined'
                        onClick={onCancel}
                        sx={{
                            ml: 1,
                            mr: 1
                        }}
                        >Cancel
                    </Button>
                </form>
        )
    }

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={initialValues}
            validate={validate}
            render={render}
        />
    )
};

export default RoomForm;