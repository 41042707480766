import {Chip} from "@mui/material";
import React from "react";
import FaceIcon from '@mui/icons-material/Face';
import {useNavigate} from "react-router-dom";

export default function ParticipantChip( { count, room_id }) {
    const navigate = useNavigate()

    const handleClick = (e) => {
        e.preventDefault();
        navigate("/conference-rooms/rooms/" + room_id + "/participants");
    }

    if (count) {
        return (
            <Chip
                onClick={handleClick}
                icon={<FaceIcon/>}
                variant='outlined'
                color={"success"}
                label={count} />
        )
    } else {
        return (
            <Chip
                icon={<FaceIcon/>}
                variant='outlined'
                onClick={handleClick}
                label='Empty'/>
        )
    }
}