import React from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TableSortLabel,
    TableContainer,
    Toolbar,
    Button
} from "@mui/material";
import {AddCircleOutline} from "@mui/icons-material";
import ParticipantChip from "./ParticipantChip";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const roomHeadCells = [
    {
        id: "id",
        numeric: false,
        disablePadding: false,
        label: "Room ID",
        sortable: true
    },
    {
        id: "name",
        numeric: false,
        disablePadding: false,
        label: "Room Name",
        sortable: false
    },
    {
        id: "status",
        numeric: false,
        disablePadding: false,
        label: "",
        sortable: false
    }
]

function RoomTableHead(props) {
    const { order, orderBy, onRequestSort } =
        props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {roomHeadCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                            hideSortIcon={!headCell.sortable}
                        >
                            {headCell.label}

                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

const RoomsTable = ({ rows, handleRowClick }) => {
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('id');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    if (!rows) return null;
    return (
        <>
        <Toolbar>
            <Button
                id="create-room-button"
                variant="outlined"
                startIcon={<AddCircleOutline />}
                onClick={(event) => handleRowClick(event, {})}>
                Create Room
            </Button>
        </Toolbar>
        <TableContainer>
            <Table>
                <RoomTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody>
                    {rows
                        .sort(getComparator(order, orderBy))
                        .map((row, index) => {
                            return (
                                <TableRow
                                    hover
                                    key={row.id}
                                    onClick={(event) => handleRowClick(event, row)}
                                >
                                    <TableCell>{row.id}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell><ParticipantChip count={row.participant_count} room_id={row.id} /></TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        </TableContainer>
        </>
    )
}

export default RoomsTable
